import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {IconButton, Table, Tooltip, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckCircle,
  faExclamationTriangle,
  faTimes,
  faExclamationCircle,
  faLongArrowAltLeft,
  faLongArrowAltRight, faEdit, faSave,
} from '@fortawesome/free-solid-svg-icons';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SortIcon from '@material-ui/icons/Sort';
import BlockedPageInfo from './BlockedPageInfo';
import Spinner from '../../Components/Spinner';
import {StyledTableCell} from '../../Components/Table';
import BoxInfo from '../../Components/BoxInfo';
import {ServiceBlockInfo} from './ServiceBlockInfo';
import {ServiceHeader} from './ServiceHeader';
import {getFetch, postFetch, useGetDataBackend} from '../../ApiBackend';
import PropTypes from 'prop-types';
import EndpointBypassTable
from '../../Pages/settings/service/EndpointBypassTable';
import InactiveActiveSwitchState
from '../../Components/InactiveActiveSwitchState';
import RedIconButton from '../../core/components/buttons/RedIconButton';
import RedTextField from '../../core/components/inputs/RedTextField';
import {displayError} from '../../ui-components/displayMsg';
import configs from '../../configs';

const DELAY_PERIOD = [-15, 'm'];

/**
 *
 * @param{string} ip
 * @return {boolean}
 */
function isPrivateIP(ip) {
  const parts = ip.split('.');
  return (
    parts[0] === '10' ||
    (parts[0] === '172' &&
      parseInt(parts[1], 10) >= 16 &&
      parseInt(parts[1], 10) <= 31) ||
    (parts[0] === '192' && parts[1] === '168')
  );
}

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function ServiceInfo() {
  const [selectedService, services] = useSelector(
      (state) => [state.selectedService, state.services],
  );

  let {
    data: {info},
    error,
    loading,
  } = useGetDataBackend('services/info/all');

  if (info && selectedService.length && selectedService !== 'all') {
    info = info.filter((i) => i.hash === selectedService);
  }

  const theme = useTheme();
  const padding = theme.spacing(3);
  const classes = makeStyles((_) => ({
    grid: {
      flex: 1,
      overflow: 'auto',
      paddingLeft: padding / 2,
      paddingRight: padding / 2,
      flexDirection: 'row',
      alignContent: 'baseline',
    },
  }))();

  return (
    <Grid
      container
      className={classes.grid}
      style={{
        overflow: 'overlay',
      }}
    >
      {(error || loading) && (
        <>
          <Grid
            item
            xs={12}
            style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
          >
            <Divider style={{marginTop: 104}}/>
          </Grid>
          <Grid
            item
            xs={12}
            style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
          >
            <ServiceHeader/>
          </Grid>
          <Grid
            item
            xs={12}
            style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
          >
            <ServiceBlockInfo title="Client DNS IPS" loading={loading}/>
          </Grid>
          <Grid
            item
            xs={12}
            style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
          >
            {/* <ServiceBlockInfo title={"Block Page Customization"}
             loading={loading}/> */}
            <ServiceBlockInfo title="Public Ips" loading={loading}/>
          </Grid>
          <Grid
            item
            xs={12}
            style={{paddingLeft: padding / 2, paddingRight: padding / 2}}
          >
            <ServiceBlockInfo title="DNS8 Server" loading={loading}/>
          </Grid>
        </>
      )}
      {!(error || loading) &&
        info.map((inf, i) => {
          const clientHash = inf.client_hash;
          const e = inf;
          const fragKey = 'fragkey-' + i;
          const {
            mqps,
            servicename,
            expire,
          } = services.find((s) => s.hash === clientHash);
          return (
            <React.Fragment key={fragKey}>
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: padding / 2,
                  paddingRight: padding / 2,
                }}
              >
                <Divider style={{marginTop: 104}}/>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: padding / 2,
                  paddingRight: padding / 2,
                }}
              >
                <ServiceHeader servicename={servicename}
                  limit={mqps}
                  serviceExpireDate={expire}/>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: padding / 2,
                  paddingRight: padding / 2,
                }}
              >
                <ServiceBlockInfo
                  title="Client DNS IPS"
                  loading={loading}
                  info={e.ips.join(', ')}
                />
              </Grid>
              <Grid item
                xs={12}
                style={
                  {paddingLeft: padding / 2, paddingRight: padding / 2}
                }>
                <ServiceBlockInfo
                  title={'Public Client Ips'}
                  loading={loading}
                  info={e.pips.join(', ')}/>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: padding / 2,
                  paddingRight: padding / 2,
                }}
              >
                <BlockedPageInfo service={clientHash}/>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: padding / 2,
                  paddingRight: padding / 2,
                }}
              >
                <ServiceBlockInfo
                  title="DNS8 Server"
                  loading={loading}
                  info={e.dnsips.join(', ')}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: padding / 2,
                  paddingRight: padding / 2,
                }}
              >
                <InternalServiceInfoTable
                  title="Client DNS IPS"
                  service={clientHash}
                  info={e}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: padding / 2,
                  paddingRight: padding / 2,
                }}
              >
                <EndpointHostsTable
                  title="Client DNS IPS"
                  service={clientHash}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: padding / 2,
                  paddingRight: padding / 2,
                }}
              >
                <EndpointBypassTable
                  service={clientHash}
                />
              </Grid>
            </React.Fragment>
          );
        })}
      <Grid item xs={12} style={{height: padding, margin: 'auto'}}/>
    </Grid>
  );
}

/**
 *
 * @param{*} service
 * @param{*} di
 * @return {JSX.Element}
 * @constructor
 */
function InternalServiceInfoTable({service, info}) {
  const [timeRange] = useSelector((state) => [state.time_range]);
  const [order, setOrder] = useState({counts: 'desc'});
  const {
    loading,
    data: {slave_status_agents_old: slaveStatusAgentsOld = {}},
    error,
  } = useGetDataBackend('internal_requests/slaves_status', {
    clientname: service,
    t_r: timeRange,
  });
  const dnsips = info.dnsips;
  let data = [];
  let nAgentServers = 0;
  if (!loading && !error) {
    const slavesData = Object.entries(slaveStatusAgentsOld).reduce(
        (
            acc,
            [
              internalDns,
              {
                has_agent: hasAgent = [],
                max,
                agent_version: agentVersion,
                host,
                ...direction
              },
            ],
        ) => {
          if (dnsips.includes(internalDns)) {
            return acc;
          }

          if (isPrivateIP(internalDns)) {
            acc.push({
              dns: internalDns,
              plugin: !!host, // has installed plugin
              agent: hasAgent, // communicates to other dns
              external: hasAgent.filter(
                  (ip) => !isPrivateIP(ip) && dnsips.indexOf(ip) === -1,
              ),
              max,
              agent_version: agentVersion,
              host,
              ...direction,
            });
          }
          return acc;
        },
        [],
    );
    const nHost = slavesData.reduce((acc, s) => {
      if (s.plugin) {
        acc.add(s.host);
      }
      return acc;
    }, new Set());

    let compare = false;
    if (order?.max) {
      const multiplier = order.max === 'asc' ? 1 : -1;
      compare = (d1, d2) =>
        multiplier * (moment(d1.max) - moment(d2.max));
    } else if (order?.name) {
      compare = sortHostNameIps(order.name);
    } else if (order?.counts) {
      const multiplier = order.counts === 'asc' ? 1 : -1;
      compare = (d1, d2) => {
        return multiplier * (d1.counts.total - d2.counts.total);
      };
    }
    if (compare) {
      slavesData.sort(compare);
    }
    data = slavesData;
    nAgentServers = nHost.size;
  }

  const columns = [
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Status</b>,
      data: 'external',
      render: (external, row) => {
        let icon;
        let label;
        if (external.length && row.plugin) {
          icon = <FontAwesomeIcon icon={faExclamationCircle} color="#BE131A"/>;
          label = 'Danger';
        } else if (
          row.plugin &&
          moment().add(...DELAY_PERIOD) - moment(row.max) <= 0
        ) {
          icon = <FontAwesomeIcon icon={faCheckCircle} color="green"/>;
          label = 'OK';
        } else {
          icon = (
            <FontAwesomeIcon icon={faExclamationTriangle} color="#DD7A29"/>
          );
          label = 'Warning';
        }
        return (
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div>{icon}</div>
            <div style={{marginLeft: 5}}>{label}</div>
          </div>
        );
      },
    },
    {
      props: {style: {textAlign: 'center', width: 110}},
      data_props: {style: {textAlign: 'center', width: 110}},
      title: (
        <>
          <b>Internal DNS server</b>
          <SortButton setOrder={setOrder} order={order} field={'name'}/>
        </>
      ),
      data: 'dns',
      render: (dns, row) => {
        const {host} = row;
        if (host) {
          return (
            <>
              [{host.toUpperCase()}] {dns}
            </>
          );
        }
        return dns;
      },
    },
    {
      props: {style: {textAlign: 'center', width: 110}},
      data_props: {style: {textAlign: 'center'}},
      data: 'plugin',
      title: <b>Has Plugin installed</b>,
      render: (plugin, row) => {
        let icon;
        let label;
        let direction;
        if (plugin) {
          icon = <FontAwesomeIcon icon={faCheck} color="green"/>;
          label = `v${row.agent_version}`;
          if (row.in && row.out) {
            direction = (
              <Tooltip
                title="Plugin sees inbound and outbound
                 requests to this DNS server.">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faLongArrowAltLeft} transform="up-3"/>
                  <FontAwesomeIcon
                    icon={faLongArrowAltRight}
                    transform="down-3"
                  />
                </span>
              </Tooltip>
            );
          } else if (row.in) {
            direction = (
              <Tooltip
                title="Plugin only sees inbound requests to this DNS Server.">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faLongArrowAltLeft}/>
                </span>
              </Tooltip>
            );
          } else if (row.out) {
            direction = (
              <Tooltip
                title="Plugin only sees outbound requests to this DNS Server.">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faLongArrowAltRight}/>
                </span>
              </Tooltip>
            );
          }
        } else {
          icon = <FontAwesomeIcon icon={faTimes} color="#BE131A"/>;
          label = 'No';
        }
        return (
          <>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{margin: 'auto', marginRight: 0}}>{icon}</div>
              <div style={{margin: 'auto', marginLeft: 5}}>{label}</div>
            </div>
            {direction}
          </>
        );
      },
    },
    {
      data_props: {
        style: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      title: <b>Notes</b>,
      data: 'external',
      render: (external, row) => {
        let text;
        if (external.length && row.plugin) {
          text = `This server does dns requests to external public ips:
           ${external.join(', ')}`;
        } else if (
          row.plugin &&
          moment().add(...DELAY_PERIOD) - moment(row.max) > 0
        ) {
          text = `This server stop sending internal requests to DNS8`;
        } else if (row.plugin) {
          text = `This server does dns requests only to internal
           dns server (internal resolution?) and the DNS8 slaves.`;
        } else {
          text = `Requests may skip DNS8 protection.`;
        }
        return <SpanTitle>{text}</SpanTitle>;
      },
    },
    {
      data_props: {
        style: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      title: (
        <>
          <b>Last Request</b>
          <SortButton setOrder={setOrder} order={order} field={'max'}/>
        </>
      ),
      data: 'max',
      render: (maxTs, row) => {
        if (row.plugin) {
          return <>{moment(maxTs).format('DD-MM-Y HH:mm:ss')}</>;
        }
        return <>-</>;
      },
    },
    {
      data_props: {
        style: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      title: (
        <>
          <b>Requests</b>
          <SortButton setOrder={setOrder} order={order} field={'counts'}/>
        </>
      ),
      data: 'counts',
      render: (maxTs, row) => {
        if (row.plugin) {
          return <>
            <Tooltip title={'Total Requests'}>
              <span>{row.counts.total}</span>
            </Tooltip>
            &nbsp;(<Tooltip title={'Inbound Requests'}>
              <span>{row.counts.in}</span>
            </Tooltip>
            &nbsp;/&nbsp;
            <Tooltip title={'Outbound Requests'}>
              <span>{row.counts.out}</span>
            </Tooltip>)</>;
        } else {
          return row.counts.total;
        }
      },
    },
  ];
  return (
    <>
      {!loading && !error && !!data.length && (
        <BoxInfo
          title={<b>Internal DNS ({nAgentServers} servers with agent)</b>}>
          <Table style={{width: '100%', tableLayout: 'fixed'}}>
            <TableHead>
              <TableRow>
                {columns.map((col, index) => {
                  const columnKey = `header${index}`;
                  return (
                    <StyledTableCell key={columnKey}
                      style={col?.props?.style}>
                      {col.title || ''}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(loading || error) && (
                <TableRowStyled>
                  <StyledTableCell colSpan={columns.length}>
                    <Spinner/>
                  </StyledTableCell>
                </TableRowStyled>
              )}
              {!loading && !error && !data.length && (
                <TableRowStyled>
                  <StyledTableCell colSpan={columns.length}>
                    No Data
                  </StyledTableCell>
                </TableRowStyled>
              )}
              {!loading &&
                !error &&
                !!data.length &&
                data.map((d, rIndex) => {
                  const row = d || {};
                  const rowKey = `row${rIndex}`;
                  return (
                    <TableRowStyled
                      key={rowKey}
                      className={
                        rIndex % 2 ?
                          'platinum-bg  opacity-30' :
                          'white-bg'
                      }
                    >
                      {columns.map(function(column, cIndex) {
                        let cellData;
                        if (row[cIndex]) {
                          cellData = row[cIndex];
                        } else if (column.render) {
                          cellData = column.render(row[column.data], row);
                        } else {
                          cellData = row[column.data];
                        }
                        const cellKey = `data_${rIndex}_${cIndex}`;
                        return (
                          <StyledTableCell
                            key={cellKey}
                            style={column?.data_props?.style}
                          >
                            {cellData}
                          </StyledTableCell>
                        );
                      })}
                    </TableRowStyled>
                  );
                })}
            </TableBody>
          </Table>
        </BoxInfo>
      )}
      {loading && <BoxInfo
        title={<><Spinner/> <b>Loading Internal DNS servers info ... </b></>}/>}
    </>
  );
}

InternalServiceInfoTable.propTypes = {
  service: PropTypes.any,
  info: PropTypes.any,
};

/**
 *
 * @param{*} children
 * @return {JSX.Element}
 * @constructor
 */
function SpanTitle({children}) {
  return (
    <Tooltip title={children}>
      <span>{children}</span>
    </Tooltip>
  );
}

SpanTitle.propTypes = {
  children: PropTypes.any,
};


/**
 *
 * @param{function} setOrder
 * @param{string} field
 * @param{object} order
 * @return {JSX.Element}
 * @constructor
 */
function SortButton({setOrder, field, order}) {
  return <IconButton
    size="small"
    onClick={() => {
      setOrder((state) => {
        if (!state[field] || state[field] === 'desc') {
          return {[field]: 'asc'};
        }
        return {[field]: 'desc'};
      });
    }}
  >
    {!order[field] && <ImportExportIcon style={{fontSize: 20}}/>}
    {order[field] === 'asc' && (
      <SortIcon
        style={{
          transform: 'scaleY(-1)',
          color: '#BE131A',
          fontSize: 20,
        }}
      />
    )}
    {order[field] === 'desc' && (
      <SortIcon style={{color: '#BE131A', fontSize: 20}}/>
    )}
  </IconButton>;
}

SortButton.propTypes = {
  setOrder: PropTypes.func,
  field: PropTypes.string,
  order: PropTypes.object,
};

/* eslint-disable no-bitwise */
/**
 *
 * @param{string} ip
 * @return {number}
 */
function ip2long(ip) {
  return ip.split('.')
      .reduce((ipInt, octet) => (ipInt << 8) + parseInt(octet, 10), 0) >>>
    0;
}

/* eslint-enable no-bitwise */
/**
 *
 * @param{string} direction
 * @return {(function(*, *): *)|*}
 */
function sortHostNameIps(direction) {
  const dir = (direction === 'asc') ? 1 : -1;
  return (d1, d2) => {
    if ((!d1.host && !d2.host) || d1.host === d2.host) {
      return dir * (ip2long(d2.dns) - ip2long(d1.dns));
    }
    if (!d1.host || !d2.host) {
      return dir * (+!!d1.host - +!!d2.host);
    }
    return dir * d2.host.localeCompare(d1.host);
  };
}

/**
 *
 * @param{object} style
 * @param{{}} props
 * @return {JSX.Element}
 * @constructor
 */
function TableRowStyled({style = {}, ...props}) {
  return <TableRow {...props}
    style={{
      border: '1px solid #F9F9F9',
      height: '38px', ...style,
    }}/>;
}

TableRowStyled.propTypes = {
  style: PropTypes.object,
};

/**
 *
 * @param{*} service
 * @return {JSX.Element}
 * @constructor
 */
function EndpointHostsTable({service}) {
  const [refresh, setRefresh] = useState(0);
  const [hosts, setHosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [changeLicences, setChangeLicences] = useState(false);
  const [delta, setDelta] = useState('');
  const [submittingDelta, setSubmittingDelta] = useState(false);
  const [status, setStatus] = useState({});
  const [globalAutoStart, setGlobalAutoStart] = useState(false);

  const [operator, keycloak] = useSelector((state) => [
    state.operator,
    state.keycloak,
  ]);
  const roles =
    keycloak.tokenParsed.resource_access[configs.KEYCLOAK_CONFIGS.clientId]?.roles || [];
  const canEdit = !(roles.includes('user') || operator.personify);

  useEffect(() => {
    getFetch(`service/${service}/endpoint/status`)
        .then((data) => {
          setStatus(data?.status ?? {});
          setGlobalAutoStart(data?.autoStart ==='true' ?? false);
        });
  }, []);

  useEffect(() => {
    setLoading(true);
    setError(false);
    getFetch(`service/${service}/endpoint/members`, {refresh})
        .then((data) => {
          setHosts(data?.hosts);
          setLoading(false);
          setError(false);
        },
        ).catch(() => {
          setLoading(false);
          setError(true);
        });
  }, [refresh]);

  const onSaveDelta = (e) => {
    e.preventDefault();
    setSubmittingDelta(true);
    postFetch(`service/${service}/endpoint/licence`, {delta}).then((data) => {
      setSubmittingDelta(false);
      setChangeLicences(false);
      setDelta('');
      setStatus(data?.status ?? {});
    }).catch((e) => {
      e.text().then((data) => {
        setSubmittingDelta(false);
        displayError('Error adding bypass:', data);
      });
    });
  };

  const onChangeAgentAutoStart = (agentId, autoStart) => (e) => {
    postFetch(`service/${service}/endpoint/${agentId}/autostart`,
        {autoStart: !autoStart})
        .then(() => {
          setHosts((hosts) => hosts.map((h) => {
            if (h.clientId === agentId) {
              return {...h, autoStart: !autoStart};
            }
            return h;
          }));
        });
  };

  const onChangeAgentStatus = (agentId, currentStatus) => (e) => {
    postFetch(`service/${service}/endpoint/${agentId}/status`,
        {status: currentStatus ? 'disabled' : 'enabled'})
        .then(() => {
          setHosts((hosts) => hosts.map((h) => {
            if (h.clientId === agentId) {
              return {...h, status: currentStatus ? 'disabled' : 'enabled'};
            }
            return h;
          }));
        });
  };

  const onChangeGlobalAutoStart = (globalAutoStart) => (e) => {
    postFetch(`service/${service}/endpoint/autoStart`,
        {autoStart: !globalAutoStart})
        .then(() => {
          setGlobalAutoStart(!globalAutoStart);
          setRefresh((r) => r + 1);
        });
  };
  const columns = [
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Agent Status</b>,
      data: 'status',
      render: (status, row) => {
        const s = status === 'enabled';
        return <InactiveActiveSwitchState
          checked={s}
          sideEffect={onChangeAgentStatus(row.clientId, s)}
          label={status ? status[0].toUpperCase() + status.slice(1) : 'Unknown'}
          disabled={!canEdit || !status}
        />;
      },
    },
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Hostname</b>,
      data: 'hostname',
    },
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Interfaces - Mac Address</b>,
      data: 'interfaces',
      render: (interfaces) => {
        return <ul style={{listStyle: 'none', padding: 0}}>
          {interfaces?.map((i) => <li
            key={i.mac_address}>{i.ip} - {i.mac_address}</li>)}
        </ul>;
      },
    },
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Agent Id</b>,
      data: 'clientId',
      render: (clientId) => clientId?.replace('service-account-', ''),
    },
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>AutoStart</b>,
      data: 'autostart',
      hide: !canEdit,
      render: (autoStart, row) => {
        const as = autoStart === 'true';
        return <InactiveActiveSwitchState
          checked={as}
          sideEffect={onChangeAgentAutoStart(row.clientId, as)}
        />;
      },
    },
    {
      props: {style: {textAlign: 'center', width: 80}},
      data_props: {style: {textAlign: 'center', width: 80}},
      title: <b>Version</b>,
      data: 'version',
    },
  ];
  return (
    <>
      {!loading && !error && !!hosts.length && (
        <BoxInfo
          title={<div style={{display: 'flex', alignItems: 'center'}}>
            <b>Hosts with Agent Endpoint
              ({status?.inUse || 0}/{status?.total || 0} Licences)</b>&nbsp;
            {canEdit && !changeLicences && <RedIconButton
              TooltipProps={{title: 'Change Licences'}}
              onClick={() => setChangeLicences(true)}
            >
              <FontAwesomeIcon icon={faEdit}/>
            </RedIconButton>}
            {changeLicences && <RedTextField
              variant="outlined"
              size="small"
              placeholder="Delta Licences"
              value={delta}
              disabled={submittingDelta}
              onChange={({target: {value}}) => setDelta(value)}
            > </RedTextField>}
            {changeLicences && delta && <RedIconButton
              TooltipProps={{title: 'Set Delta Licences'}}
              onClick={onSaveDelta}
            >
              <FontAwesomeIcon icon={faSave}/>
            </RedIconButton>}
            <div style={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
            }}>
              <span>Global AutoStart: &nbsp;</span>
              <InactiveActiveSwitchState
                checked={globalAutoStart}
                sideEffect={onChangeGlobalAutoStart(globalAutoStart)}
              />
            </div>
          </div>
          }>
          <Table style={{width: '100%', tableLayout: 'fixed'}}>
            <TableHead>
              <TableRow>
                {columns.map((col, index) => {
                  const columnKey = `header${index}`;
                  if (col.hide) return null;
                  return (
                    <StyledTableCell key={columnKey}
                      style={col?.props?.style}>
                      {col.title || ''}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(loading || error) && (
                <TableRowStyled>
                  <StyledTableCell colSpan={columns.length}>
                    <Spinner/>
                  </StyledTableCell>
                </TableRowStyled>
              )}
              {!loading && !error && !hosts.length && (
                <TableRowStyled>
                  <StyledTableCell colSpan={columns.length}>
                    No Data
                  </StyledTableCell>
                </TableRowStyled>
              )}
              {!loading &&
                !error &&
                !!hosts.length &&
                hosts.map((d, rIndex) => {
                  const row = d || {};
                  const rowKey = `row${rIndex}`;
                  return (
                    <TableRowStyled
                      key={rowKey}
                      className={
                        rIndex % 2 ?
                          'platinum-bg  opacity-30' :
                          'white-bg'
                      }
                    >
                      {columns.map(function(column, cIndex) {
                        if (column.hide) return null;
                        let cellData;
                        if (row[cIndex]) {
                          cellData = row[cIndex];
                        } else if (column.render) {
                          cellData = column.render(row[column.data], row);
                        } else {
                          cellData = row[column.data];
                        }
                        const cellKey = `data_${rIndex}_${cIndex}`;
                        return (
                          <StyledTableCell
                            key={cellKey}
                            style={column?.data_props?.style}
                          >
                            {cellData}
                          </StyledTableCell>
                        );
                      })}
                    </TableRowStyled>
                  );
                })}
            </TableBody>
          </Table>
        </BoxInfo>
      )}
      {loading && <BoxInfo
        title={<><Spinner/> <b>Loading Endpoint Hosts info ... </b></>}/>}
    </>
  );
}

EndpointHostsTable.propTypes = {
  service: PropTypes.any,
  info: PropTypes.any,
};

